import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import ui from './modules/ui';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    globalAlert: null,
    currentProjectId: null,
    langList: [{ lang: 'en' }, { lang: 'da' }, { lang: 'de' }],
    projectList: [],
  },
  getters: {
    globalAlert: (state) => state.globalAlert,
    currentProjectId: (state) => state.currentProjectId,
    projectList: (state) => state.projectList,
    langList: (state) => state.langList,
  },
  actions: {
    SET_PROJECT_ID({ commit }, projectId) {
      commit('updateCurrentProject', projectId);
    },
    SET_PROJECTLIST({ commit }, projectList) {
      commit('updateCurrentProjectList', projectList);
    },
  },
  mutations: {
    updateCurrentProject: (state, projectId) => {
      state.currentProjectId = projectId;
    },
    updateCurrentProjectList: (state, projectList) => {
      state.projectList = projectList;
    },
    alert(state, alert) {
      state.globalAlert = {
        show: true,
        timeout: 8000,
        ...alert,
      };
    },
  },
  modules: {
    auth,
    ui,
  },
});
