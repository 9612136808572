import axios from 'axios';
import { DateTime } from 'luxon';
import store from '@/store/index';
import router from '@/router';

const service = {
  api: process.env.VUE_APP_API_URL,

  async getOptions(inOptions) {
    if (!inOptions?.noAuth && store.getters.user && DateTime.fromISO(store.getters.user.expires) < DateTime.now()) {
      console.log('token expired, refreshing');

      const user = await this.refreshAuth(store.getters.user.refreshToken);

      store.commit('updateUser', user);
    }

    const token = store.getters.authToken;

    if (!inOptions?.noAuth && token) {
      return {
        ...inOptions,
        headers: {
          Authorization: `Bearer ${token}`,
          ...inOptions?.headers,
        },
      };
    } else {
      return inOptions;
    }
  },
  handleResponseError(err) {
    if (err?.response?.status === 401) {
      // not authenticated, consider user logged out
      store.dispatch('LOGOUT');
      router.push('/');
    } else {
      let message;
      // let details;
      if (err.response?.data.type) {
        if (err.response.data.type === 'https://tools.ietf.org/html/rfc7231#section-6.5.1') {
          // details = '';
          // for (let x in err.response.data.errors) {
          //   details += `${x}: ${err.response.data.errors[x]}\r\n`;
          // }
        }
        message = err.response.data.title;
      } else {
        message = err.message;
        // details = err.response?.data;
      }
      store.commit('alert', {
        show: true,
        title: 'API error',
        color: '#AD2433',
        textColor: 'white',
        message,
        // details,
      });
    }
  },
  async handleResponse(task) {
    try {
      const response = await task;

      if (response.headers['x-pagination']) {
        return {
          paging: JSON.parse(response.headers['x-pagination']),
          data: response.data,
        };
      }

      return response.data;
    } catch (err) {
      this.handleResponseError(err);
      throw err;
    }
  },

  async post(endpoint, payload, options) {
    return this.handleResponse(axios.post(`${this.api}/${endpoint}`, payload, await this.getOptions(options)));
  },
  postJsonString(endpoint, payload, options) {
    return this.post(endpoint, JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
      },
      ...options,
    });
  },
  async get(endpoint, payload) {
    return this.handleResponse(axios.get(`${this.api}/${endpoint}`, await this.getOptions(payload)));
  },
  async put(endpoint, payload) {
    return this.handleResponse(axios.put(`${this.api}/${endpoint}`, payload, await this.getOptions()));
  },
  async delete(endpoint, payload) {
    return this.handleResponse(axios.delete(`${this.api}/${endpoint}`, await this.getOptions(payload)));
  },

  //Endpoints
  changePassword(passwordChanges) {
    return this.post('auth/changePassword', passwordChanges);
  },
  refreshAuth(refreshCode) {
    return this.postJsonString('auth/refresh', refreshCode, { noAuth: true });
  },
  resetPassword(username) {
    return this.postJsonString('auth/resetPassword', username, {
      noAuth: true,
    });
  },
  resetPasswordToken(options) {
    return this.post('auth/resetPasswordToken', options, { noAuth: true });
  },
  login(credentials) {
    return this.post('auth/login', credentials, { noAuth: true });
  },

  adminResetPassword(user) {
    return this.put('auth/adminPasswordReset', user);
  },

  getProjects() {
    return this.get('project');
  },
  getProjectById(id) {
    return this.get(`project/${id}`);
  },

  createProject(project) {
    return this.put('project', project);
  },
  updateProject(project) {
    return this.put('project', project);
  },
  updateAppetite(Appetite) {
    return this.put('project/Appetite', Appetite);
  },
  deleteProjectById(id) {
    return this.delete(`project/${id}`);
  },


  putImpactLevel(impactLevel) {
    return this.put('ImpactLevel', impactLevel);
  },

  getReportById(id) {
    return this.get(`Reports/${id}`);
  },
  getProjectReports(id) {
    return this.get(`Reports/projectReports/${id}`);
  },

  putRisk(risk) {
    return this.put('Risk', risk);
  },
  getRiskById(id) {
    return this.get(`Risk/version/${id}`)
  },
  // Send riskOriginalId
  getRiskByriskOriginalId(riskOriginalId) {
    return this.get(`Risk/${riskOriginalId}`);
  },
  getProjectRisks(id) {
    return this.get(`Risk/ProjectRisks/${id}`)
  },
  getLatestTwoRisks(id) {
    return this.get(`Risk/ProjectRisks/latestTwo/${id}`)
  },
  getProjectHistory(id) {
    return this.get(`Risk/projectHistroy/${id}`);
  },

  getDeprecatedRisk(id) {
    return this.get(`Risk/Deprecated/${id}`);
  },
  getRisks() {
    return this.get('Risk');
  },
  getRiskHistory(RiskOriginalId) {
    return this.get(`Risk/riskHistory/${RiskOriginalId}`);
  },

  getRemarks(RiskOriginalId) {
    return this.get(`Remark/${RiskOriginalId}`);
  },
  putRemark(remark) {
    return this.put('Remark', remark);
  },
  deleteRemark(id) {
    return this.delete(`Remark/${id}`);
  },

  getUsers() {
    return this.get('user');
  },
  getUserById(id) {
    return this.get(`user/${id}`);
  },
  createOrEditUser(user) {
    return this.put('user', user);
  },
  updateUserState(id) {
    return this.put(`user/state/${id}`);
  },

  getCompanies() {
    return this.get('Company');
  },
  getCompanyById(id) {
    return this.get(`Company/${id}`);
  },
  getUserCompany() {
    return this.get(`Company/userCompany`);
  },
  createOrEditCompany(Company) {
    return this.put('Company', Company);
  },
  getMatrix(id) {
    return this.get(`matrix/${id}`)
  }
};



export default service;
