<template>
  <v-btn class="px-4" v-bind="attributes" v-on="$listeners" elevation="0">
    <slot />
  </v-btn>
</template>
<script>
export default {
  name: 'r-btn',
  props: {
    soft: Boolean,
    text: Boolean,
  },
  computed: {
    attributes() {
      return {
        color: this.$vuetify.theme.dark ? 'blue lighten-3' : 'black',
        elevation: this.$vuetify.theme.dark && !this.text ? 2 : 0,
        text: this.text,
        ...this.$attrs,
      };
    },
  },
};
</script>