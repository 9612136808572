<template>
  <v-card color="transparent" v-bind="$attrs" v-on="$listeners" class="d-flex flex-column">
    <div :style="$vuetify.theme.dark ? 'background-color: rgba(20, 20, 20, 0.8)' : 'background-color: rgba(255, 255, 255, 0.8)'">
      <slot name="header"></slot>
      <v-divider />
    </div>
    <v-sheet :style="primaryBackgroundStyle" class="my-0" style="height: 100%">
      <slot></slot>
    </v-sheet>
  </v-card>
</template>
<script>
export default {
  name: 'r-card',
  props: {
    allTransparent: Boolean,
    transparentDark: Boolean,
  },
  computed: {
    primaryBackgroundStyle() {
      if (this.allTransparent) {
        return this.$vuetify.theme.dark ? 'background-color: rgba(20, 20, 20, 0.8)' : 'background-color: rgba(255, 255, 255, 0.8)';
      } else if (this.transparentDark) {
        return this.$vuetify.theme.dark ? 'background-color: rgba(0, 0, 0, 0.8)' : 'background-color: rgba(255, 255, 255, 0.8)';
      } else {
        return null;
      }
    },
  },
};
</script>
