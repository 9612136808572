import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import RBtn from './components/RBtn.vue';
import RCard from './components/RCard.vue';
import util from './plugins/util';
import i18n from "./i18n";

Vue.config.productionTip = false;

Object.defineProperty(Vue.prototype, '$util', { value: util });


Vue.component('r-btn', RBtn);
Vue.component('r-card', RCard);

// Now the app has started!

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
