import Vue from "vue";
import VueI18n from "vue-i18n";
import translations from '@/plugins/translations';


Vue.use(VueI18n);
export default new VueI18n({
  locale: 'da', // set locale
  fallbackLocale: 'en', // set fallback language
  messages: translations,
});