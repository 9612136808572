const translations = {
  en: {
    message: {
      Projects: 'Choose Project',
      Search: 'Search',
      New: 'New risk',
    },
    PageTitles: {
      Company: 'Companies',
      CompanyDetails: 'Company Details',
      MyCompany: 'My Company',
      Users: 'Users',
      UserDetails: 'User Details',
      ProjectDetails: 'Projects Details',
      Project: 'Projects',
      Risk: 'Risk',
      RiskHistory: 'Risk history',
      Deprecated: '[Deprecated]',
      NewestRisk: 'Newest Risk',
      Matrix: 'Risk Matrix',
      Reports: 'Reports',
      ProjectHistory: 'Project History',
      SquareMatrix: 'Square data',
    },
    ButtonsLabel: {
      Edit: 'Edit',
      AddCompany: 'Add Company',
      AddUser: 'Add User',
      AddProject: 'Add Project',
      AddRisk: 'Add Risk',
      Disable: 'Disable',
      Activate: 'Activate',
      Save: 'Save',
      Cancel: 'Cancel',
      GoBack: 'Go Back',
      AddRemark: 'Add Remark',
      DeleteRemark: 'Delete Remark',
      AddSelected: 'Add selected',
      AddEntireCompany: 'Add Entire Company',
      AddByEmail: 'Add User By Email',
      RemoveFromPermissions: 'Remove Permissions',
      AddReport: 'Add new report',
      SeeDetails: 'See details',
      CloseRow: 'Close item',
      ResetSlider: 'Reset Changes',
      FullScreenMatrix: 'FullScreen',
      SaveRiskAppetite: 'Save risk Appetite',
    },
    DialogsTitles: {
      EditCompany: 'Edit Company',
      AddNewCompany: 'Add New Company',
      EditProject: 'Edit Project',
      AddNewProject: 'Add New Project',
      EditUser: 'Edit User',
      AddNewUser: 'Add New User',
      EditRisk: 'Edit Risk',
      AddRisk: 'Add New Risk',
      AddRemark: 'Add New Remark',
      EditImpact: 'Edit Impact Level',
    },
    UserListHeaders: {
      Name: 'Name',
      Email: 'Email',
      PhoneNumber: 'Phone Number',
      CompanyName: 'Company Name',
      SetUserPassword: 'Set user password',
      SendPasswordResetEmail: 'Send password reset email',
      Permissions: 'Permissions',
      InternalPermissionsUsers: 'Internal Users',
      ExternalPermissionsUsers: 'External Users',
    },
    UserDetailsListHeaders: {
      State: 'User Active State',
      Roles: 'Roles',
    },
    ProjectListHeaders: {
      Name: 'Project Name',
      Leader: 'Leader',
      Expenses: 'Expenses',
      Created: 'Created',
      StartDate: 'Start Date',
      Updated: 'Updated',
      EndDate: 'End Date',
      EstimatedEnd: 'Estimated End Date',
    },
    CompaniesListHeaders: {
      Address: 'Address',
      Country: 'Country',
      Cvr: 'Cvr',
      Name: 'Name',
      PhoneNumber: 'Phone Number',
      AmountOfUsers: 'Amount Of Users',
    },
    RiskListHeaders: {
      HideRisksButton: 'Hide inactive risks',
      HideActiveRisksButton: 'Hide active risks',
      ShowAll: 'Show all',
      Risks: 'Risks',
      Risk: 'Risk',
      Probability: 'Probability',
      TimePlan: 'Time Plan',
      Expenses: 'Expenses',
      Scope: 'Scope',
      Resources: 'Resources',
      Owner: 'Owner',
      Version: 'Version',
      OverallConsequences: 'Overall consequence',
      Status: 'Status',
      ConsequenceParameters: 'Consequence Parameters',
      HasOccurred: 'Has occured',
      WillNotOccur: 'Will not occur',
      EditedBy: 'Edited by',
      RiskId: 'Risk ID',
      Hide: 'Hide',
      Inactive: 'Inactive',
      Active: 'Active',
    },
    RiskDetails: {
      RiskTitle: 'Risk Title',
      Description: 'Description',
      RiskOwner: 'Risk Owner',
      Consequence: 'Consequence',
      Probability: 'Probability',
      Expenses: 'Expenses',
      Resources: 'Resources',
      TimeLine: 'TimeLine',
      Scope: 'Scope',
      Mitigation: 'Mitigation',
      ContingencyPlan: 'Contingency Plan',
      Remarks: 'Remarks',
    },
    RiskHeaders: {
      RiskTitle: 'Risk Title',
      Description: 'Description',
      RiskOwner: 'Risk Owner',
      Mitigation: 'Mitigation',
      ContingencyPlan: 'Contingency Plan',
      Remarks: 'Remarks',
      RiskType: 'Type',
      RiskStatus: 'Risk Status',
      CommitStatus: 'Commit Status',
      Remark: 'Write Remark',
      Hide: 'Hide',
      Inactive: 'Inactive',
      Active: 'Active',
    },
    RiskProfile: {
      Expenses: 'Expenses',
      Scope: 'Scope',
      Time: 'Time',
    },
    RiskConsequenceText: {
      VerySmall: 'Very small',
      Small: 'Small',
      Medium: 'Medium',
      Big: 'Large',
      VeryBig: 'Very large',
    },
    RiskStatusText: {
      None: 'None',
      Risk: 'Risk',
      DidNotOccur: 'Did not occur',
      Occurred: 'Occurred',
    },
    RiskType: {
      None: 'None',
      Organization: 'Organization',
      TechnicalSolution: 'Technical Solution',
      Suppliers: 'Suppliers',
      Stakeholders: 'Stakeholders',
      GeneralUncertainty: 'General Uncertainty',
      ProjectCertainty: 'Project Certainty',
      Uncategorized: 'Uncategorized',
    },
    CommitStatus: {
      None: 'None',
      Pending: 'Pending',
      Active: 'Active',
      Superseded: 'Superseded',
      Deleted: 'Deleted',
    },
    FrontPageUserItems: {
      FrontPage: 'Frontpage',
      Projects: 'Projects',
      Users: 'Users',
      ProjectHandling: 'Project Handling',
    },
    FrontPageAdminItems: {
      Companies: 'Companies',
      MyCompany: 'My Company',
      AdminOptions: 'Admin Options',
    },
    ProjectItems: {
      Risk: 'Risk',
      RiskMatrix: 'Risk Matrix',
      RiskProfile: 'Risk Profile',
      Reports: 'Reports',
      ImpactLevel: 'Impact Level',
    },
    ImpactLevelItems: {
      Impact: 'Impact',
      ImpactLevel: 'Levels',
      TimePercentage: 'Time Percentage',
      ExpensesPercentage: ' Expenses Percentage',
      ProbabilityPercentage: 'Probability Percentage'
    },
    ImpactLevelText: {
      One: 'Very Small 1',
      Two: 'Small 2',
      Three: 'Medium 3',
      Four: 'Large 4',
      Five: 'Very Large 5',
    },
    PermissionsText: {
      Read: 'Read',
      ReadAndWrite: 'Read/Write',
      FullAccess: 'Full Access',
    },
    ReportHeaders: {
      Title: 'Title',
      Created: 'Created',
      Creator: 'Written By',
      ProjectStatus: 'Projekt Status',
      Challanges: 'Challanges',
      ReportText: 'Report Text',
      Summary: 'Summary',
      Resources: 'Resources',
    },
    ReportProjectStatus: {
      None: 'None',
      Upcoming: 'Upcoming',
      Pending: 'Pending',
      Overdue: 'Overdue',
      NotStarted: 'Not Started',
      Active: 'Active',
      OnTrack: 'On Track',
      OffTrack: 'Off Track',
      Priority: 'Priority',
      Canceled: 'Canceled',
    },
    MatrixHeaders: {
      ShowNoRiskHistory: 'Disable Risk History',
      Text: 'Konsekvenser',
      One: 'Very Small 1',
      Two: 'Small 2',
      Three: 'Medium 3',
      Four: 'Large 4',
      Five: 'Very Large 5',
    },
    MatrixTitle: {
      Older: 'Older risk',
      Newer: 'Newer risk',
    },
    RiskAppetite: {
      Low: 'Low',
      Standard: 'Standard',
      High: 'High'
    },
  },
  da: {
    message: {
      hello: 'Hej verden',
      LangSelect: 'Skift sprog',
      SortAfter: 'Sorter efter',
      Projects: 'Vælg projekt',
      Company: 'Virksomheder',
      Search: 'søg',
      New: 'Ny risiko',
    },
    PageTitles: {
      Company: 'Virksomheder',
      CompanyDetails: 'Virksomheds Detaljer',
      UserCompany: 'Min Virksomhed',
      User: 'Brugere',
      UserDetails: 'Bruger Detaljer',
      Project: 'Projekter',
      ProjectDetails: 'Projekt Detaljer',
      Risk: 'Risiko',
      RiskDetails: 'Risiko Detaljer',
      RiskHistory: 'Risiko historie',
      Deprecated: '[Forældet]',
      NewestRisk: 'Nyeste risk',
      Matrix: 'Risiko Matrix',
      Reports: 'Raports',
      ProjectHistory: 'Projekt historie',
      SquareMatrix: 'Firkant data',
    },
    ButtonsLabel: {
      Edit: 'Rediger',
      AddCompany: 'Tilføj Virksomhed',
      AddUser: 'Tilføj Bruger',
      AddProject: 'Tilføj Projekt',
      AddRisk: 'Tilføj Risiko',
      Disable: 'De-Aktiver',
      Activate: 'Aktiver',
      Save: 'Gem',
      Cancel: 'Annuller',
      GoBack: 'Tilbage',
      AddRemark: 'Tilføj Bemærkninger',
      DeleteRemark: 'Delete Bemærkninger',
      AddSelected: 'Tilføj valgte',
      AddEntireCompany: 'Tilføj hele virksomheden',
      AddByEmail: 'Tilføj via Email',
      RemoveFromPermissions: 'Fjern rettigheder',
      AddReport: 'Tilføj ny raport',
      SeeDetails: 'Se detajler',
      CloseRow: 'Luk item',
      ResetSlider: 'Nulstil Ænderinger',
      FullScreenMatrix: 'Fuld skærm',
      SaveRiskAppetite: 'Gem riskio appetit',
    },
    DialogsTitles: {
      EditCompany: 'Rediger virksomhed',
      AddNewCompany: 'Tilføj virksomhed',
      EditProject: 'Rediger projekt',
      AddNewProject: 'Tilføj projekt',
      EditUser: 'Rediger bruger',
      AddNewUser: 'Tilføj bruger',
      EditRisk: 'Rediger risiko',
      AddRisk: 'Tilføj risiko',
      AddRemark: 'Tilføj bemærking',
      EditImpact: 'Redigere Indtryks niveau',
    },
    UserListHeaders: {
      Name: 'Navn',
      Email: 'Email',
      PhoneNumber: 'Telefon Nummer',
      CompanyName: 'Virksomheds Navn',
      SetUserPassword: 'Sæt brugerens kodeord',
      SendPasswordResetEmail: 'Send email om nulstilling af kodeord',
      Permissions: 'Rettigheder',
      CompanyPermissionsUsers: 'Bruger med Rettigheder',
      ExternalPermissionsUsers: 'Eksterne Bruger med Rettigheder',
    },
    UserDetailsListHeaders: {
      State: 'Activerings status',
      Roles: 'Roller',
    },
    ProjectListHeaders: {
      Name: 'Projekt Navn',
      Leader: 'Leder',
      Expenses: 'Omkostninger',
      AmountOfRisks: 'Antal risici',
      Created: 'Oprettet',
      Start: 'Startet',
      StartDate: 'Startet dato',
      Updated: 'Opdateret',
      End: 'Slut dato',
      EstimatedEnd: 'Estimereded slutning',
    },
    CompaniesListHeaders: {
      Address: 'Adresse',
      Country: 'Land',
      Cvr: 'Cvr',
      Name: 'Navn',
      PhoneNumber: 'Telefon nummer',
      AmountOfUsers: 'Antal brugere',
    },
    RiskListHeaders: {
      HideRisksButton: 'Vis kun aktive risici',
      HideActiveRisksButton: 'Vis kun inaktive risici',
      ShowAll: 'Vis alle',
      Risks: 'Risici',
      Risk: 'Risiko',
      Probability: 'Sandsynlighed',
      TimePlan: 'Tidsplan',
      Expenses: 'Omkostninger',
      Scope: 'Omfang',
      Budget: 'Budget',
      Resources: 'Ressourcer',
      Owner: 'Ejer',
      Versions: 'Versioner',
      OverallConsequences: 'Overordnede Konsekvenser',
      Status: 'Status',
      ConsequenceParameters: 'Konsekvensparametre',
      HasOccurred: 'Er indtruffet',
      WillNotOccur: 'Indtræder ikke',
      SeeDetails: 'Se detaljer',
      EditedBy: 'Redigeret af',
      Version: 'Versioner',
      RemarksCount: 'Bemærkninger',
      Remark: 'Skriv bemærkning',
      RiskId: 'Risk ID',
      Hide: 'Hide',
      Inactive: 'Inactive',
      Active: 'Active',
    },
    RiskDetails: {
      RiskTitle: 'Risk Titel',
      Description: 'Beskrivelse',
      RiskOwner: 'Risiko Ejer',
      Consequence: 'Konsekvens',
      Probability: 'Sandsynlighed',
      Expenses: 'Omkostinger',
      Resource: 'Budget',
      TimeLine: 'Tidsplan',
      Scope: 'Omfang',
      Mitigation: 'Risiko Reduktion',
      ContingencyPlan: 'Beredskabsplan',
      Remarks: 'Bemærkninger',
    },
    RiskHeaders: {
      RiskTitle: 'Risk Titel',
      Description: 'Beskrivelse',
      RiskOwner: 'Risiko Ejer',
      Consequence: 'Konsekvens',
      Probability: 'Sandsynlighed',
      Expenses: 'Omkostinger',
      Resource: 'Budget',
      TimeLine: 'Tidsplan',
      Scope: 'Omfang',
      Mitigation: 'Risiko Reduktion',
      ContingencyPlan: 'Beredskabsplan',
      Remarks: 'Bemærkninger',
      RiskType: 'Type',
      RiskStatus: 'Risiko Status',
      CommitStatus: 'Commit Status',
      Hide: 'Skjul',
      Inactive: 'Inaktive',
      Active: 'Aktive',
    },
    RiskProfile: {
      Expenses: 'Omkostninger',
      Scope: 'Omfang',
      Time: 'Tid',
    },
    RiskConsequenceText: {
      VerySmall: 'Meget lille',
      Small: 'Lille',
      Medium: 'Mellem',
      Big: 'Stor',
      VeryBig: 'Meget Stor',
    },
    RiskStatusText: {
      None: 'Ingen',
      Risk: 'Risiko',
      DidNotOccur: 'Indtræder ikke',
      Occurred: 'Er indtruffet',
    },
    RiskType: {
      None: 'Ingen',
      Organization: 'Organisation',
      TechnicalSolution: 'Tekniske Løsning',
      Suppliers: 'Leverandører',
      Stakeholders: 'Interessenter',
      GeneralUncertainty: 'Generel usikkerhed',
      ProjectCertainty: 'Projektsikkerhed',
      Uncategorized: 'Ikke kategoriseret',
    },
    CommitStatus: {
      None: 'None',
      Pending: 'uafgjort',
      Active: 'Aktiv',
      Superceded: 'Afløst',
      Deleted: 'Slettet',
    },
    FrontPageUserItems: {
      FrontPage: 'Forside',
      Risk: 'Projekter',
      Users: 'Brugere',
      Projects: 'Projekt håndtering',
    },
    FrontPageAdminItems: {
      Companies: 'Virksomheder',
      MyCompanies: 'Min Virksomhed',
      AdminDropDownName: 'Admin Muligheder',
    },
    ProjectItems: {
      Risk: 'Risiko',
      Risk_matrix: 'Risiko Matrix',
      Risk_profile: 'Risiko Profil',
      Rapports: 'Rapporter',
      ImpactLevel: 'Indtryk Level',
    },
    ImpactLevelItems: {
      ImpactListName: 'Indtryk',
      ImpactLevelID: 'Level',
      TimePercentage: 'Tids Procent',
      ExpensesPercentage: 'Omkostninger Procent',
      ProbabilityPercentage: 'Sandsynlighed Procent'
    },
    ImpactLevelIdText: {
      One: 'Meget lille 1',
      Two: 'Lille 2',
      Three: 'Mellem 3',
      Four: 'Store 4',
      Five: 'Meget stor 5',
    },
    PermissionsText: {
      Read: 'Læsse',
      ReadAndWrite: 'Skrive/Læse',
      FullAccess: 'Alle Rettigeheder',
    },
    ReportHeaders: {
      Title: 'Title',
      Created: 'Oprettet',
      Creator: 'Skrevet af',
      projectStatus: 'Projekt status',
      Challanges: 'Udfordringer',
      ReportText: 'Raport Tekst',
      Summary: 'Resume',
      Resources: 'Ressourcer',
    },
    ReportProjectStatus: {
      None: '',
      Upcoming: '',
      Pending: '',
      Overdue: '',
      Not_started: '',
      Active: '',
      on_track: '',
      off_track: '',
      Priority: '',
      Canceled: '',
    },
    MatrixHeaders: {
      ShowNoRiskHistory: 'Vis ikke aktive Risks',
      Text: 'Konsekvenser',
      One: 'Meget lille 1',
      Two: 'Lille 2',
      Three: 'Mellem 3',
      Four: 'Store 4',
      Five: 'Meget stor 5',
    },
    MatrixTitle: {
      Older: 'Gamle risiko',
      Newer: 'Ny risiko',
    },
    RiskAppetite: {
      Low: 'Lav',
      Standard: 'Standart',
      High: 'Høj'
    },
  },
  de: {
    message: {
      hello: 'Hello wie gehts',
      LangSelect: 'Sprache ändern',
      SortAfter: 'Sortieren nach',
      Projects: 'Projekte',
      // Company: 'DE',
      // GoBack: 'DE',
      // Save: 'DE',
      // Edit: 'DE',
    },
    RiskListHeaders: {
      Risks: 'Risiken',
      Risk: 'Risiko',
      Probability: 'Wahrscheinlichkeit',
      TimePlan: 'Zeitplan',
      Expenses: 'Kostet',
      Scope: 'Umfang',
      Resources: 'Ressourcen',
      Owner: 'Besitzer',
      OverallConsequences: 'Gesamtfolgen',
    },
    RiskDetails: {
      RiskTitle: 'Risiko Titel',
      Description: 'Beschreibung',
      // RiskOwner: 'DE',
      // Consequence: 'DE',
      // Probability: 'DE',
      // Expenses: 'DE',
      // Resource: 'DE',
      // TimeLine: 'DE',
      // Scope: 'DE',
      // Mitigation: 'DE',
      // ContingencyPlan: 'Contingency Plan',
      // Remarks: 'DE',
    },
  },
};

export default translations;
