import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: '#3369E5',
        secondary: '#fcfcfc',
        background: '#ddd',
        navbar: '#fff',
        activeButton: '#2196f3',
        button: '#878787',
        dataTableRow: '#fff',
        dataTableRowHover: '#eee',
        searchField: '#fff',
        linkColor: '#3369E5'
      },
      dark: {
        anchor: '#5FAAFF',
        primary: '#3369E5',
        secondary: '#fff',
        background: '#333',
        navbar: '#141414',
        activeButton: '#2196f3',
        button: '#878787',
        dataTableRow: '#1e1e1e',
        dataTableRowHover: '#3e3e3e',
        searchField: '#1e1e1e',
        linkColor: '#fcfcfc'
      },
    },
  },
});
