import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

Vue.use(VueRouter);

const DEFAULT_TITLE = 'Risk Management';

const routes = [
  {
    path: '/',
    component: () => import('../views/Home.vue'),
    redirect: { name: 'FrontPage' },
    children: [
      {
        path: 'frontpage',
        name: 'FrontPage',
        meta: {
          title: 'Management Panel',
        },
        component: () => import('@/views/FrontPage.vue'),
      },
      {
        path: 'user',
        name: 'User',
        component: () => import('@/views/User.vue'),
        redirect: { name: 'UserList' },
        children: [
          {
            path: 'list',
            name: 'UserList',
            component: () => import('@/components/UserList.vue'),
          },
          {
            path: ':Id',
            name: 'UserDetails',
            component: () => import('@/components/details/UserDetails.vue'),
          },
        ],
      },
      {
        path: 'company',
        name: 'Company',
        meta: {
          title: 'Companies',
        },
        component: () => import('@/views/Company.vue'),
        redirect: { name: 'CompanyList' },
        children: [
          {
            path: 'list',
            name: 'CompanyList',
            meta: {
              title: 'Companies',
            },
            component: () => import('@/components/CompanyList.vue'),
          },
          {
            path: ':Id',
            name: 'CompanyDetails',
            props: true,
            meta: {
              title: 'companyDetails',
            },
            component: () => import('@/components/details/CompanyDetails.vue'),
          },
        ],
      },

      {
        path: 'projects',
        name: 'Projects',
        props: true,
        meta: {
          title: 'Project Handling',
        },
        component: () => import('@/components/ProjectList.vue'),
      },
      {
        path: 'projectDetails/:projectId',
        name: 'projectDetails',
        props: true,
        meta: {
          title: 'Project details',
        },
        component: () => import('@/components/details/ProjectDetails.vue'),
      },
      {
        path: 'project/:projectId',
        name: 'Project',
        component: () => import('@/views/Project.vue'),
        redirect: { name: 'RiskList' },
        children: [
          {
            path: 'risk',
            name: 'RiskList',
            component: () => import('@/components/RiskList.vue'),
          },
          {
            path: 'riskmatrix',
            name: 'RiskMatrix',
            meta: {
              title: 'Risiko Matrix',
              requiresAuth: true,
            },
            component: () => import('@/components/RiskMatrix.vue'),
          },
          {
            path: 'risktriangle',
            name: 'RiskTriangle',
            meta: {
              title: 'Risiko Trekant',
              requiresAuth: true,
            },
            component: () => import('@/components/RiskTriangle.vue'),
          },
          {
            path: 'risk/:riskId',
            name: 'RiskDetails',
            meta: {
              title: 'Risiko',
            },
            component: () => import('@/components/details/RiskDetails.vue'),
          },
          {
            path: 'riskHistory/:historyId',
            name: 'RiskVersionDetails',
            meta: {
              title: 'Risiko',
            },
            component: () => import('@/components/details/RiskDetails.vue'),
          },
          {
            path: 'rapports',
            name: 'rapports',
            meta: {
              title: 'Rapporter',
            },
            component: () => import('@/components/ProjectReport.vue'),
          },
          {
            path: 'rapports/:reportId',
            name: 'reportDetails',
            meta: {
              title: 'Raport',
            },
            component: () => import('@/components/details/ReportDetails.vue'),
          },
          {
            path: 'impactlevel',
            name: 'ImpactLevel',
            meta: {
              title: 'Impact Level',
              requiresAuth: true,
            },
            component: () => import('@/components/ImpactLevel.vue'),
          },
        ],
      },
      {
        path: 'mycompany',
        name: 'MyCompany',
        component: () => import('@/components/UserCompany.vue'),
      },
      {
        path: 'resetPassword',
        name: 'Reset Password',
        component: () => import('@/views/ResetPassword.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title ? `${to.meta.title} - ${DEFAULT_TITLE}` : DEFAULT_TITLE;
  });
});

router.beforeEach(async (to, from, next) => {
  let success = await store.dispatch('ENSURE_AUTH');
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    success = store.getters.isAdmin;
  } else if (to.matched.some((record) => record.meta.requiresManager)) {
    success = store.getters.isManager;
  } else if (!to.matched.some((record) => record.meta.requiresAuth)) {
    success = true;
  }

  // if not, redirect to login page.
  if (!success) {
    next({
      name: 'frontPage',
    });
  } else {
    next();
  }
});

export default router;
