import { DateTime } from 'luxon';
import _ from 'lodash';
import i18n from '../i18n';
const util = {
  
  /*
     0 #D6D8D9 // light grey
     1 #49BD55 // green
     2 #FFF400 // yellow
     3 #FFF400 // yellow
     4 #ED6351 // red
     5 #ED6351 // red
     6 #8C8D90 // dark grey  
  
  */


  /**
   * @param  {} value - Int32
   *
   * @returns string (color)
   */
  colorize(value) {

    if (value == 0) return '#D6D8D9';
    if (value == 1) return '#49BD55';
    if (value == 2) return '#FFF400';
    if (value == 3) return '#FFF400';
    if (value == 4) return '#ED6351';
    if (value == 5) return '#ED6351';
    if (value == 6) return '#8C8D90';
  },

  /**
   * @param  {} value - Int32, Properbility - int32
   *
   * @returns string (color)
   */
  colorizeByProperbility(value, Properbility) {
    if (Properbility == 0 || Properbility == 6) {
      return 'grey';
    }
    else {
      if (value == 1) return '#49BD55';
      if (value == 2) return '#FFF400';
      if (value == 3) return '#FFF400';
      if (value == 4) return '#ED6351';
      if (value == 5) return '#ED6351';
    }
  },

  /**
   * used for coloring risklist
   * @param  {} value - Int32, riskAppetite - int32, probability - 32
   *
   * @returns string (color)
   */
  colorizeByMatrix(value, riskAppetite, probability) {
    if (probability == 0) return '#ED6351';
    if (probability == 6) return '#ED6351';


    if (riskAppetite == 0) {
      if (probability == 1 && value == 1) return '#49BD55'
      if (probability == 1 && value == 2) return '#49BD55'
      if (probability == 1 && value == 3) return '#FFF400'
      if (probability == 1 && value == 4) return '#FFF400'
      if (probability == 1 && value == 5) return '#ED6351'

      if (probability == 2 && value == 1) return '#49BD55'
      if (probability == 2 && value == 2) return '#FFF400'
      if (probability == 2 && value == 3) return '#FFF400'
      if (probability == 2 && value == 4) return '#FFF400'
      if (probability == 2 && value == 5) return '#ED6351'

      if (probability == 3 && value == 1) return '#FFF400'
      if (probability == 3 && value == 2) return '#FFF400'
      if (probability == 3 && value == 3) return '#FFF400'
      if (probability == 3 && value == 4) return '#ED6351'
      if (probability == 3 && value == 5) return '#ED6351'

      if (probability == 4 && value == 1) return '#FFF400'
      if (probability == 4 && value == 2) return '#FFF400'
      if (probability == 4 && value == 3) return '#ED6351'
      if (probability == 4 && value == 4) return '#ED6351'
      if (probability == 4 && value == 5) return '#ED6351'

      if (probability == 5 && value == 1) return '#ED6351'
      if (probability == 5 && value == 2) return '#ED6351'
      if (probability == 5 && value == 3) return '#ED6351'
      if (probability == 5 && value == 4) return '#ED6351'
      if (probability == 5 && value == 5) return '#ED6351'
    }
    else if (riskAppetite == 1) {
      if (probability == 1 && value == 1) return '#49BD55'
      if (probability == 1 && value == 2) return '#49BD55'
      if (probability == 1 && value == 3) return '#49BD55'
      if (probability == 1 && value == 4) return '#49BD55'
      if (probability == 1 && value == 5) return '#FFF400'

      if (probability == 2 && value == 1) return '#49BD55'
      if (probability == 2 && value == 2) return '#49BD55'
      if (probability == 2 && value == 3) return '#FFF400'
      if (probability == 2 && value == 4) return '#FFF400'
      if (probability == 2 && value == 5) return '#ED6351'

      if (probability == 3 && value == 1) return '#49BD55'
      if (probability == 3 && value == 2) return '#FFF400'
      if (probability == 3 && value == 3) return '#FFF400'
      if (probability == 3 && value == 4) return '#ED6351'
      if (probability == 3 && value == 5) return '#ED6351'

      if (probability == 4 && value == 1) return '#49BD55'
      if (probability == 4 && value == 2) return '#FFF400'
      if (probability == 4 && value == 3) return '#ED6351'
      if (probability == 4 && value == 4) return '#ED6351'
      if (probability == 4 && value == 5) return '#ED6351'

      if (probability == 5 && value == 1) return '#FFF400'
      if (probability == 5 && value == 2) return '#ED6351'
      if (probability == 5 && value == 3) return '#ED6351'
      if (probability == 5 && value == 4) return '#ED6351'
      if (probability == 5 && value == 5) return '#ED6351'
    }
    else {
      if (probability == 1 && value == 1) return '#49BD55'
      if (probability == 1 && value == 2) return '#49BD55'
      if (probability == 1 && value == 3) return '#49BD55'
      if (probability == 1 && value == 4) return '#49BD55'
      if (probability == 1 && value == 5) return '#FFF400'

      if (probability == 2 && value == 1) return '#49BD55'
      if (probability == 2 && value == 2) return '#49BD55'
      if (probability == 2 && value == 3) return '#49BD55'
      if (probability == 2 && value == 4) return '#FFF400'
      if (probability == 2 && value == 5) return '#FFF400'

      if (probability == 3 && value == 1) return '#49BD55'
      if (probability == 3 && value == 2) return '#49BD55'
      if (probability == 3 && value == 3) return '#FFF400'
      if (probability == 3 && value == 4) return '#FFF400'
      if (probability == 3 && value == 5) return '#FFF400'

      if (probability == 4 && value == 1) return '#49BD55'
      if (probability == 4 && value == 2) return '#FFF400'
      if (probability == 4 && value == 3) return '#FFF400'
      if (probability == 4 && value == 4) return '#FFF400'
      if (probability == 4 && value == 5) return '#ED6351'

      if (probability == 5 && value == 1) return '#FFF400'
      if (probability == 5 && value == 2) return '#FFF400'
      if (probability == 5 && value == 3) return '#FFF400'
      if (probability == 5 && value == 4) return '#ED6351'
      if (probability == 5 && value == 5) return '#ED6351'
    }
  },


  /**
   * ```
   * Formats currency
   * ```
   * @param  {} value - Int32
   */
  formatCurrency(value) {
    return `${Number(value).toLocaleString('da-DK')},-`;
  },

  /**
   * @param  {} input - Bool
   *
   * @returns String
   */
  formatDisabledState(input) {
    if (input == false) {
      return 'Active';
    } else {
      return 'Disabled';
    }
  },

  /**
   * @param  {} input - DateTime
   * @param  {} withTime - Bool
   *
   * @returns datetime
   */
  formatDate(input, withTime) {
    let dt;

    if (!input) {
      return null;
    }

    if (input instanceof DateTime) {
      dt = input;
    } else if (input instanceof Date) {
      dt = DateTime.fromJSDate(input);
    } else {
      dt = DateTime.fromJSDate(new Date(input));
    }

    return withTime ? dt.toFormat('dd-MM-yyyy HH:mm') : dt.toFormat('dd-MM-yyyy');
  },

  /**
   * @param  {} input - Array
   *
   * @returns int32
   */
  formatRiskToCount(input) {
    return input.length;
  },

  /**
   * @param  {} value - Array
   *
   * @returns int32
   */

  consequenceCalculator(timeLine, expenses, scope, resource, probability) {
    if (probability == 0 || probability == 6) {
      if (probability == 0) return i18n.t('RiskListHeaders.WillNotOccur');
      if (probability == 6) return i18n.t('RiskListHeaders.HasOccurred');
      else {
        return '';
      }
    }
    else {
      let overallValue = timeLine + expenses + scope + resource;
      let result = overallValue / 4;
      let rounded = Math.round(result);
      return rounded;
    }


  },
  /**
   * ```
   * Converts int to a description of consequence
   * ```
   * @param  {} value - Int32
   *
   * @returns string
   */
  convertConsequenceToText(value) {
    if (value == 0) return i18n.t('RiskListHeaders.WillNotOccur');
    if (value == 1) return i18n.t('RiskConsequenceText.VerySmall');
    if (value == 2) return i18n.t('RiskConsequenceText.Small');
    if (value == 3) return i18n.t('RiskConsequenceText.Medium');
    if (value == 4) return i18n.t('RiskConsequenceText.Big');
    if (value == 5) return i18n.t('RiskConsequenceText.VeryBig');
    if (value == 6) return i18n.t('RiskListHeaders.HasOccurred');
  },
  /**
   * ```
   * Converts int to a description of impact
   * ```
   * @param  {} value - int32
   *
   * @returns string
   */
  impactLeveIdToText(value) {
    if (value == 1) return i18n.t('ImpactLevelText.One');
    if (value == 2) return i18n.t('ImpactLevelText.Two');
    if (value == 3) return i18n.t('ImpactLevelText.Three');
    if (value == 4) return i18n.t('ImpactLevelText.Four');
    if (value == 5) return i18n.t('ImpactLevelText.Five');
  },

  /**
   * ```
   * Converts the int value for permissions into text
   * ```
   * @param  {} value - int32
   *
   * @returns string
   */
  userPermissionsToText(value) {
    if (value == 0) return i18n.t('PermissionsText.Read');
    if (value == 1) return i18n.t('PermissionsText.ReadAndWrite');
    if (value == 2) return i18n.t('PermissionsText.FullAccess');
  },

  /**
   * ```
   *  Used on datepicker.
   *  Translates the month to either danish, english or german
   * ```
   * @returns object
   */
  localizeCalendar() {
    return {
      'header-date-format': (date) => _.upperFirst(DateTime.fromISO(date).setLocale(i18n.locale).toFormat('MMMM y')),
      'month-format': (date) => _.upperFirst(DateTime.fromISO(date).setLocale(i18n.locale).toFormat('MMMM')),
      'weekday-format': (date) => DateTime.fromISO(date).setLocale(i18n.locale).toFormat('ccccc'),
      'show-week': true,
      'show-adjacent-months': true,
      'locale-first-day-of-year': 4,
      'first-day-of-week': 1,
      'title-date-format': (date) => _.upperFirst(DateTime.fromISO(date).setLocale(i18n.locale).toFormat('d. MMMM y')),
    };
  },

  /**
   * ```
   *  Used on Risk details and CreateEditRisk
   *  Translates the Risk Status to either danish, english or german
   * ```
   * @returns String
   */
  RiskStatusByNumber(value) {
    if (value == 0) return i18n.t('RiskStatusText.None');
    if (value == 1) return i18n.t('RiskStatusText.Risk');
    if (value == 2) return i18n.t('RiskStatusText.DidNotOccur');
    if (value == 3) return i18n.t('RiskStatusText.Occurred');
  },

  /**
   * ```
   *  Used on project report details and report
   *  Translates the Project status number to text in either danish, english or german
   * ```
   * @returns String
   */
  RiskTypeByNumber(value) {
    if (value == 0) return i18n.t('RiskType.None');
    if (value == 1) return i18n.t('RiskType.Organization');
    if (value == 2) return i18n.t('RiskType.TechnicalSolution');
    if (value == 3) return i18n.t('RiskType.Suppliers');
    if (value == 4) return i18n.t('RiskType.Stakeholders');
    if (value == 5) return i18n.t('RiskType.GeneralUncertainty');
    if (value == 6) return i18n.t('RiskType.ProjectCertainty');
    if (value == 7) return i18n.t('RiskType.Uncategorized');
  },

  /**
   * ```
   *  Used on Risk details and CreateEditRisk
   *  Translates the Commit Status to either danish, english or german
   * ```
   * @returns String
   */
  CommitStatusByNumber(value) {
    if (value == 0) return i18n.t('CommitStatus.None');
    if (value == 1) return i18n.t('CommitStatus.Pending');
    if (value == 2) return i18n.t('CommitStatus.Active');
    if (value == 3) return i18n.t('CommitStatus.Superseded');
    if (value == 4) return i18n.t('CommitStatus.Deleted');
  },

  projectStatusByNumber(value) {
    if (value == 0) return i18n.t('ReportProjectStatus.None');
    if (value == 1) return i18n.t('ReportProjectStatus.Upcoming');
    if (value == 2) return i18n.t('ReportProjectStatus.Pending');
    if (value == 3) return i18n.t('ReportProjectStatus.Overdue');
    if (value == 4) return i18n.t('ReportProjectStatus.NotStarted');
    if (value == 5) return i18n.t('ReportProjectStatus.Active');
    if (value == 6) return i18n.t('ReportProjectStatus.OnTrack');
    if (value == 7) return i18n.t('ReportProjectStatus.OffTrack');
    if (value == 8) return i18n.t('ReportProjectStatus.Priority');
    if (value == 9) return i18n.t('ReportProjectStatus.Canceled');
  },

  /**
   * @param  {} risks - array
   *
   * @returns array of int32 from 0-3 which indicates what colour the risk triangle should be.
   */
  calculateTriangleStatus(risks) {
    const greenList = [2, 3, 4, 5, 17, 18, 82, 257];
    const yellowList = [6, 19, 20, 83, 84, 258, 626];
    const redList = [21, 85, 86, 259, 260, 261, 627, 628, 629, 630];

    let timeStatus = 0;
    let expensesStatus = 0;
    let scopeStatus = 0;

    let maxTime = Math.max.apply(
      Math,
      risks.map(function (o) {
        return o.timeLine;
      })
    );
    let maxExpenses = Math.max.apply(
      Math,
      risks.map(function (o) {
        return o.expenses;
      })
    );
    let maxScope = Math.max.apply(
      Math,
      risks.map(function (o) {
        return o.scope;
      })
    );
    let maxProb = Math.max.apply(
      Math,
      risks.map(function (o) {
        if (o.probability == 0 || o.probability == 6) return null;
        return o.probability;
      })
    );

    if (maxProb == 0) return null;

    if (timeStatus < 1 && greenList.includes(Math.pow(maxProb, 4) + maxTime)) {
      timeStatus = 1;
    }
    if (timeStatus < 2 && yellowList.includes(Math.pow(maxProb, 4) + maxTime)) {
      timeStatus = 2;
    }
    if (timeStatus < 3 && redList.includes(Math.pow(maxProb, 4) + maxTime)) {
      timeStatus = 3;
    }

    if (expensesStatus < 1 && greenList.includes(Math.pow(maxProb, 4) + maxExpenses)) {
      expensesStatus = 1;
    }
    if (expensesStatus < 2 && yellowList.includes(Math.pow(maxProb, 4) + maxExpenses)) {
      expensesStatus = 2;
    }
    if (expensesStatus < 3 && redList.includes(Math.pow(maxProb, 4) + maxExpenses)) {
      expensesStatus = 3;
    }

    if (scopeStatus < 1 && greenList.includes(Math.pow(maxProb, 4) + maxScope)) {
      scopeStatus = 1;
    }
    if (scopeStatus < 2 && yellowList.includes(Math.pow(maxProb, 4) + maxScope)) {
      scopeStatus = 2;
    }
    if (scopeStatus < 3 && redList.includes(Math.pow(maxProb, 4) + maxScope)) {
      scopeStatus = 3;
    }
    if (timeStatus == 0 && expensesStatus == 0 && scopeStatus == 0) return null;

    return { timeStatus, expensesStatus, scopeStatus };
  },

  /**
   * @param  user - the user logged in
   * @param  permissionsList - the list of permissionms for project
   *
   * @returns boolean value if user has full access.
   */
  HasFullAccess(thisUser, permissionsList) {
    if (permissionsList !== undefined && permissionsList.some((x) => x.userId == thisUser.id)) {
      const user = permissionsList.find((x) => x.userId == thisUser.id);
      if (user.userPermissions == 2) {
        return true;
      }
      return false;
    }
    return false;
  },

  /**
   * @param  user - the user logged in
   * @param  permissionsList - the list of permissionms for project
   *
   * @returns boolean value if user has Read And Write.
   */
  HasReadAndWrite(thisUser, permissionsList) {
    if (permissionsList !== undefined && permissionsList.some((x) => x.userId == thisUser.id)) {
      const user = permissionsList.find((x) => x.userId == thisUser.id);
      if (user.userPermissions == 1 || user.userPermissions == 2) {
        return true;
      }
      return false;
    }
    return false;
  },
};

export default util;
